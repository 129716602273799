import { assign } from '@xstate/immer'
import { actions, send, spawn, assign as xAssign } from 'xstate'
import { keys, isEmpty, isNil } from 'ramda'
import {
  getFilteredReasons,
  parseErrorMessage,
  getDefaultCountry,
  checkCopartDelivery
} from '../../utils'
import { setPropertyInLocalStorage } from '../../utils/storage'
import appointmentPopupMachine from '../appointmentPopup'
import yardDetailsMachine from '../yardDetailsAndYardStatus'
import lotInfoItemsMachine from '../lotInfoItems'

const { choose, pure } = actions

const selfServeFormFieldsInitialStates = {
  firstName: '',
  lastName: '',
  truckType: '',
  licenseState: '',
  licensePlateNumber: '',
  transporterId: '',
  driversLicence: '',
  registeredUser: '',
  vehicleDetails: '',
  lotInfoItems: [],
  memberRequestId: ''
}

export const setCountryCode = assign((ctx) => {
  ctx.form.countryCode = getDefaultCountry()
})

export const setLanguage = assign((ctx, event) => {
  ctx.language = event?.language
})

export const setUserType = assign((ctx, event) => {
  ctx.form.inputs.userType = event.value
})

export const clearUserTypeInStorage = () =>
  setPropertyInLocalStorage('userType', '')

export const setUserTypeInStorage = (ctx) => {
  setPropertyInLocalStorage('userType', ctx.form.inputs.userType)
}

export const setReasons = assign((ctx, event) => {
  ctx.form.inputs.reasonCodes = event.value
})

export const setPhoneNumber = assign((ctx, event) => {
  ctx.form.inputs.phoneNumber = event.phone
})

export const setParsedPhoneNumber = assign((ctx, event) => {
  ctx.form.inputs.parsedPhone = {
    dialCode: event.countryData && event.countryData.dialCode,
    rawPhoneNumber: event.parsedPhone && event.parsedPhone.nationalNumber,
    isValid: event.parsedPhone && event.parsedPhone.isValid()
  }
})
export const setUsertypeByPhoneNum = assign((ctx, event) => {
  ctx.form.inputs.userType = event.data.userType
  ctx.form.reasonOptions = getFilteredReasons(
    ctx.form.inputs.userType,
    event.data.reasons,
    event.data.userTypes
  )
})
export const resetUserType = assign((ctx, event) => {
  ctx.form.inputs.userType = ''
  ctx.form.inputs.reasonCodes = []
})

export const setPhoneNumberInStorage = (ctx) => {
  setPropertyInLocalStorage(
    'phoneNumber',
    ctx.form.inputs?.parsedPhone?.rawPhoneNumber
  )
}

export const setAppointmentDetails = assign((ctx, event) => {
  ctx.transporter = {
    ...ctx.transporter,
    appointments: [...event.data.appointmentDetails]
  }
})

export const setTransporterDetailsToCtx = assign((ctx, event) => {
  ctx.transporter = {
    ...ctx.transporter,
    details: { ...event.data.transporterDetails }
  }

  // ctx.form.inputs = { ...ctx.form.inputs, ...event.data.transporterDetails }
})

export const setSlots = assign((ctx, event) => {
  ctx.schedule.availableSlots = event.data

  const selectFirstActiveDate = keys(ctx.schedule.availableSlots).find(
    (date) => !isEmpty(ctx.schedule.availableSlots[date])
  )

  ctx.schedule.selectedDate = selectFirstActiveDate
  ctx.schedule.selectedSlot = null
  ctx.schedule.selectedScheduleId = null
  ctx.schedule.selectedStartDateTime = null
})

export const setAppointmentId = assign((ctx, event) => {
  ctx.appointmentId = event.data.appointmentId
})

export const setVirtualQueueId = assign((ctx, event) => {
  ctx.requestId = event.data.virtualQueueId
})

export const setSelfServeFormDetails = assign((ctx, event) => {
  ctx.form.inputs = { ...ctx.form.inputs, ...event.params } // change it later
})

export const setReasonsOptions = assign((ctx, event) => {
  ctx.form.reasonOptions = getFilteredReasons(
    ctx.form.inputs.userType,
    event.reasons,
    event.userTypes
  )
})

export const setDateToCtx = assign((ctx, event) => {
  ctx.schedule.selectedDate = event.date
  ctx.schedule.selectedSlot = null
  ctx.schedule.selectedScheduleId = null
  ctx.schedule.selectedStartDateTime = null
})

export const setSlotToCtx = assign((ctx, event) => {
  const selectedSlotObj = ctx.schedule.availableSlots[
    ctx.schedule.selectedDate
  ].find((item) => item.schedule_id === event.id)

  ctx.schedule.selectedSlot = selectedSlotObj.slot
  ctx.schedule.selectedScheduleId = event.id
  ctx.schedule.selectedStartDateTime = selectedSlotObj.startDateTime
})

export const setValidatedPickupLots = assign((ctx, event) => {
  ctx.validatedPickupLots = event.data
})

export const setPickupSublot = assign((ctx, event) => {
  ctx.pickupSublot = event.data
})

export const emptyPickupSublot = assign((ctx) => {
  ctx.pickupSublot = {}
})

export const clearFormData = assign((ctx, event) => {
  ctx.form.inputs = { ...ctx.form.inputs, ...selfServeFormFieldsInitialStates }
})

export const setLotNumberRequiredError = assign((ctx, event) => {
  ctx.error = 'Lot number details are required'
})

export const setServerError = assign((ctx, event) => {
  ctx.error = parseErrorMessage(event?.data?.response)
})

export const clearError = assign((ctx, event) => {
  ctx.error = ''
})

export const setFormFieldValue = assign((ctx, event) => {
  ctx.form.inputs[event.name] = event.value
})

export const setLotInfoItems = assign((ctx, event) => {
  ctx.form.inputs.lotInfoItems = event.value
  const pickupLots = event.value.filter((val) => val.reasons.includes('PKVEH'))
  ctx.isCopartDelivery = checkCopartDelivery(event.value)
  ctx.pickupLots = pickupLots
})

export const toggleTextAllowed = assign((ctx) => {
  ctx.form.inputs.textAllowed = !ctx.form.inputs.textAllowed
})

export const setYardCloseMessage = assign((ctx, event) => {
  ctx.form.yardClosedMessage = event.data.yardClosedMessage
})

export const sendTextSelectedGAEvent = choose([
  {
    cond: 'isTextAllowed',
    actions: send('SEND_EVENT_GA', {
      category: 'User',
      action: 'Opted out text messages'
    })
  },
  {}
])

export const seFacilityId = assign((ctx, event) => {
  ctx.form.inputs.facilityId = event.facilityId
})

export const spawnAppointmentPopupMachine = xAssign((ctx) => {
  const appointments = ctx.transporter.appointments?.map((appointment) => {
    return {
      ...appointment,
      appointmentRef: spawn(
        appointmentPopupMachine.withContext({
          ...appointment,
          details: {
            ...ctx.transporter.details,
            stateCode: ctx.form.inputs.stateCode,
            facilityId: ctx.form.inputs.facilityId,
            phoneNumber: ctx.form.inputs.phoneNumber,
            rawPhoneNumber: ctx.form.inputs?.parsedPhone?.rawPhoneNumber
          },
          latitude: ctx.latitude,
          longitude: ctx.longitude,
          closestYard: ctx.closestYard,
          countryCode: ctx.form.countryCode,
          timeZone: ctx.timeZone
        }),
        { devTools: true }
      )
    }
  })
  return {
    ...ctx,
    transporter: { ...ctx.transporter, appointments: [...appointments] }
  }
})

export const incrementAppointmentCounter = assign((ctx, event) => {
  ctx.appointmentCounter = ctx.appointmentCounter + 1
})

export const decrementAppointmentCounter = assign((ctx, event) => {
  ctx.appointmentCounter = ctx.appointmentCounter - 1
})

export const setLatitudeAndLongitude = assign((ctx, event) => {
  ctx.latitude = event.latitude
  ctx.longitude = event.longitude
})

export const sendLatitideLongitudeToAllAppointmentPopups = choose([
  {
    cond: (ctx) => {
      return (
        !isNil(ctx.transporter.appointments) &&
        !isEmpty(ctx.transporter.appointments)
      )
    },
    actions: 'sendLatitideLongitudeToAllAppointmentPopupsAction'
  },
  {}
])

export const sendLatitideLongitudeToAllAppointmentPopupsAction = pure((ctx) => {
  return ctx?.transporter?.appointments?.map((appointment) => {
    if (appointment.appointmentRef)
      return send(
        () => ({
          type: 'UPDATE_LAT_LONG',
          latitude: ctx.latitude,
          longitude: ctx.longitude
        }),
        {
          to: appointment.appointmentRef
        }
      )
    return null
  })
})

export const removeYardCloseMessage = assign((ctx, event) => {
  ctx.form.yardClosedMessage = ''
  ctx.form.yardOpen = false
})

export const clearAppointmentCounter = assign((ctx, event) => {
  ctx.appointmentCounter = 0
})

export const spawnYardDetailsMachine = xAssign((ctx) => {
  const yardDetailsMachineRef = spawn(yardDetailsMachine.withContext({}), {
    devTools: true
  })
  return { ...ctx, yardDetailsMachineRef }
})

export const stopSpawnedYardDetailsMachine = assign((ctx) => {
  ctx.yardDetailsMachineRef.stop()
})

export const setFacilityDetails = assign((ctx, event) => {
  ctx.form.inputs.stateCode = event.data?.stateCode
  ctx.form.inputs.facilityId = event.data?.facilityId
  ctx.form.inputs.latitude = event.data.latitude
  ctx.form.inputs.longitude = event.data.longitude
  ctx.form.yardOpen = event.data?.yardOpen
  ctx.form.yardClosedMessage = event.data?.yardClosedMessage
  ctx.timeZone = event.data?.timeZone
})

export const setClosestYard = assign((ctx, event) => {
  ctx.closestYard.stateCode = event.data?.stateCode
  ctx.closestYard.facilityId = event.data?.facilityId
})

export const sendLocationDetailsChangeEventToYardDetailsMachine = choose([
  {
    cond: (ctx) => {
      return !ctx.form.inputs.facilityId
    },
    actions: send(
      (ctx, event) => {
        return {
          type: 'LOCATION_DETAILS_CHANGE',
          data: {
            latitude: ctx.latitude,
            longitude: ctx.longitude,
            facilities: event?.facilities,
            yardFromUrl: event?.yardFromUrl
          }
        }
      },
      {
        to: (ctx) => ctx.yardDetailsMachineRef
      }
    )
  },
  {}
])

export const sendClosestYardToAllAppointmentPopups = pure((ctx, event) => {
  return ctx?.transporter?.appointments?.map((appointment) => {
    if (appointment.appointmentRef)
      return send(
        () => ({
          type: 'UPDATE_CLOSEST_YARD_DETAILS',
          closestYard: {
            stateCode: event.data?.stateCode,
            facilityId: event.data?.facilityId
          }
        }),
        {
          to: appointment.appointmentRef
        }
      )
    return null
  })
})

export const stopSpawnedAppointmentPopupMachines = assign((ctx) => {
  ctx.transporter.appointments = ctx.transporter.appointments?.map(
    (appointment) => {
      appointment.appointmentRef.stop()
      return {
        ...appointment
      }
    }
  )
})

export const spawnLotInfoItemsMachineAction = choose([
  {
    cond: (ctx) => ctx.lotInfoItemsMachineRef
  },
  {
    actions: 'spawnLotInfoItemsMachine'
  }
])

export const spawnLotInfoItemsMachine = xAssign((ctx, event, meta) => {
  const lotInfoItemsMachineRef = spawn(
    lotInfoItemsMachine.withContext({
      reasonCodes: ctx.form?.inputs?.reasonCodes,
      reasons: ctx.form?.inputs?.reasonCodes,
      lotInfoItems: ctx.form?.inputs?.lotInfoItems || [],
      numberOfLots: 1,
      reasonOptions: ctx.form?.reasonOptions,
      userType: ctx.form?.inputs?.userType,
      facilityId: ctx.form?.inputs?.facilityId,
      countryCode: ctx?.form.countryCode
    }),
    {
      devTools: true,
      name: 'lotGroup'
    }
  )
  return { ...ctx, lotInfoItemsMachineRef }
})

export const stopLotInfoItemsMachine = assign((ctx) => {
  ctx.lotInfoItemsMachineRef.stop()
  ctx.lotInfoItemsMachineRef = null
})

// syncing validated gate pass pin lots to lotInfoItems
export const syncGatePassPinLotDetails = assign((ctx) => {
  ctx.form.inputs.lotInfoItems = ctx.form.inputs.lotInfoItems.map((item) => {
    const gatePassPinLot = ctx.validatedPickupLots?.find(
      (pickupLot) => pickupLot.gate_pass_pin === item.gate_pass_pin
    )
    if (gatePassPinLot) {
      return { ...gatePassPinLot }
    }
    return { ...item }
  })
})

export const sendMemberNumberChangeUpdate = choose([
  {
    cond: (_, event) => {
      return !isNaN(Number(event.value))
    },
    actions: [
      send(
        (_, event) => {
          console.log(event)
          return {
            type: 'MEMBER_NUMBER_CHANGE',
            memberNumber: event.value
          }
        },
        { to: (ctx) => ctx.lotInfoItemsMachineRef }
      )
    ]
  },
  {
    actions: send(
      () => ({
        type: 'MEMBER_NUMBER_CHANGE',
        memberNumber: ''
      }),
      { to: (ctx) => ctx.lotInfoItemsMachineRef }
    )
  }
])
