import { Machine, send } from 'xstate'
import { sendEventGA } from '../../utils/sendAnalytics'
import { stampCustomLog } from '../../utils/logs'

const formFieldsEvents = {
  FORM_FIELD_CHANGE: {
    actions: 'setFormFieldValue',
    target: '.validating'
  },
  LOT_INFO_ITEMS_CHANGE: {
    actions: 'setLotInfoItems',
    target: '.validating'
  },
  TOGGLE_TEXT_ALLOWED: {
    actions: 'toggleTextAllowed',
    target: '.validating'
  },
  BACK_TO_HOME_PAGE: {
    actions: ['stopLotInfoItemsMachine', 'clearFormData'],
    target: '#kiosk.landing.validating'
  }
}

const validatingState = (isMember) => ({
  initial: 'idle',
  always: [
    {
      cond: 'isStackUS',
      target: '.stackUSValidation'
    },
    {
      target: '.stackUKValidation'
    }
  ],
  states: {
    idle: {},
    stackUSValidation: {
      always: [
        {
          cond: isMember
            ? 'areNonTowProvidersAndMemberFieldsValidUS'
            : 'areNonTowProvidersFieldsValidUS',
          target: isMember
            ? '#editingFormMember.errorFree'
            : '#editingFormNonMember.errorFree'
        },
        {
          target: isMember
            ? '#editingFormMember.error'
            : '#editingFormNonMember.error'
        }
      ]
    },
    stackUKValidation: {
      always: [
        {
          cond: isMember
            ? 'areNonTowProvidersAndMemberFieldsValidUK'
            : 'areNonTowProvidersFieldsValidUK',
          target: isMember
            ? '#editingFormMember.errorFree'
            : '#editingFormNonMember.errorFree'
        },
        {
          target: isMember
            ? '#editingFormMember.error'
            : '#editingFormNonMember.error'
        }
      ]
    }
  }
})

const machine = Machine({
  id: 'kiosk',
  initial: 'landing',
  entry: [
    'spawnYardDetailsMachine',
    'setCountryCode',
    'clearUserTypeInStorage',
    (ctx) => {
      stampCustomLog({
        event: 'homeURL',
        currentState: 'landing'
      })
    }
  ],
  on: {
    NAVIGATE_TO: {
      actions: 'pushToHistory'
    },
    SEND_EVENT_GA: {
      actions: (ctx, event) =>
        sendEventGA({
          category: event.category,
          action: event.action
        })
    },
    SET_LATITUDE_AND_LONGITUDE: {
      actions: [
        send('SEND_EVENT_GA', {
          category: 'User',
          action: 'Allowed location sharing'
        }),
        'setLatitudeAndLongitude',
        'sendLatitideLongitudeToAllAppointmentPopups',
        'sendLocationDetailsChangeEventToYardDetailsMachine'
      ]
    },
    UPDATE_FACILITY_DETAILS: {
      actions: ['setClosestYard', 'sendClosestYardToAllAppointmentPopups']
    },
    SET_LANGUAGE: {
      actions: ['setLanguage']
    }
  },
  context: {
    transporter: {
      details: {},
      appointments: {}
    },
    form: {
      inputs: {
        textAllowed: true, // this will be true initially as always phone number is filled in form
        numberOfLots: 1
      },
      countryCode: '',
      reasonOptions: []
    }, // landing and selfserve details
    pickupLots: [],
    schedule: {
      availableSlots: {},
      selectedSlot: '',
      selectedDate: ''
    },
    requestId: '',
    appointmentCounter: 0,
    closestYard: {}
  },
  states: {
    landing: {
      id: 'landing',
      tags: ['landing'],
      initial: 'validating',
      on: {
        PICK_USER_TYPE: {
          actions: ['setUserType', 'setReasonsOptions', 'setUserTypeInStorage'],
          target: '#landing.validating.locationValidation'
        },
        SET_REASONS_OPTIONS: {
          actions: 'setReasonsOptions'
        },
        PICK_REASONS: {
          actions: 'setReasons',
          target: '#landing.validating.locationValidation'
        },
        ENTER_PHONE_NUMBER: {
          actions: 'setPhoneNumber',
          target: '#landing.validating.locationValidation'
        },
        PARSE_AND_VALIDATE_PHONE_NUMBER: {
          actions: ['setParsedPhoneNumber'],
          target: '#landing.validating.locationValidation'
        },
        USERTYPE_BY_PHONE_NUMBER: {
          cond: 'hasValidPhoneNumberAndFacilityId',
          target: '.setUserTypeByPhoneNumber'
        },
        STATE_CODE_CHANGE: {
          actions: 'setFormFieldValue',
          target: '.validating'
        },
        FACILITY_CHANGE: [
          {
            cond: 'hasFacilityId',
            actions: [
              'removeYardCloseMessage',
              'seFacilityId',
              send(
                (ctx) => ({
                  type: 'FETCH_YARD_STATUS',
                  data: {
                    stateCode: ctx.form.inputs.stateCode,
                    facilityId: ctx.form.inputs.facilityId
                  }
                }),
                {
                  to: (ctx) => ctx.yardDetailsMachineRef
                }
              )
            ],
            target: '.validating'
          },
          {
            actions: ['removeYardCloseMessage', 'seFacilityId'],
            target: '.validating'
          }
        ],
        UPDATE_FACILITY_DETAILS: {
          actions: ['setFacilityDetails', 'setClosestYard'],
          target: '#landing.validating.locationValidation'
        }
      },
      states: {
        setUserTypeByPhoneNumber: {
          invoke: {
            id: 'getUserTypeService',
            src: 'getUserTypeService',
            onDone: [
              {
                cond: 'hasUserType',
                actions: ['setUserTypeInStorage', 'setUsertypeByPhoneNum'],
                target: '#landing.validating.locationValidation'
              },
              {
                actions: ['resetUserType'],
                target: '#landing.validating.locationValidation'
              }
            ],
            onError: [
              {
                target: '#kiosk.landing.serverError'
              }
            ]
          }
        },
        validating: {
          id: 'validating',
          initial: 'fieldValidations',
          states: {
            fieldValidations: {
              on: {
                '': [
                  {
                    cond: 'areAllFieldsFilledAndPhoneNumberValid',
                    target: '#landing.errorFree'
                  },
                  {
                    target: '#landing.error'
                  }
                ]
              }
            },
            locationValidation: {
              always: [
                {
                  cond: 'isLocationNotShared',
                  target: 'locationNotSharedError'
                },
                {
                  cond: 'isLocationNotMatching',
                  target: 'locationNotMatchingError'
                },
                {
                  target: 'fieldValidations'
                }
              ]
            },
            locationNotSharedError: {
              tags: ['locationNotSharedError'],
              exit: ['clearError']
            },
            locationNotMatchingError: {
              tags: ['locationNotMatchingError'],
              exit: ['clearError']
            }
          }
        },
        error: {
          tags: ['error'],
          exit: ['clearError']
        },
        errorFree: {
          on: {
            CONTINUE: [
              {
                cond: 'isTowProvider',
                actions: 'setPhoneNumberInStorage',
                target: '#kiosk.towProvider'
              },
              {
                actions: ['setPhoneNumberInStorage'],
                target: '#kiosk.nonTowProvider'
              }
            ]
          }
        },
        serverError: {
          tags: ['error', 'serverError'],
          exit: ['clearError'],
          on: {
            CONTINUE: [
              {
                cond: 'isTowProvider',
                actions: 'setPhoneNumberInStorage',
                target: '#kiosk.towProvider'
              },
              {
                actions: ['setPhoneNumberInStorage'],
                target: '#kiosk.nonTowProvider'
              }
            ]
          }
        }
      }
    },
    towProvider: {
      id: 'towProvider',
      initial: 'checkingForAppointment',
      states: {
        checkingForAppointment: {
          invoke: {
            id: 'getAppointmentDetailsService',
            src: 'getAppointmentDetailsService',
            onDone: [
              {
                cond: 'hasAppointment', // for today
                actions: [
                  'setAppointmentDetails',
                  'setTransporterDetailsToCtx'
                ],
                target: 'appointmentFound'
              },
              {
                actions: ['setTransporterDetailsToCtx'],
                target: 'noAppointmentFound'
              }
            ],
            onError: [
              {
                cond: 'isAppointmentDetailsServiceResponse400',
                target: 'noAppointmentFound'
              },
              {
                actions: 'setServerError',
                target: '#kiosk.landing.serverError'
              }
            ]
          }
        },
        appointmentFound: {
          tags: ['hasAppointment'],
          initial: 'checkingForNumberOfAppointments',
          on: {},
          states: {
            checkingForNumberOfAppointments: {
              on: {
                '': [
                  {
                    cond: 'hasOnlyOneAppointment',
                    target: 'oneAppointment'
                  },
                  {
                    target: 'multipleAppointments'
                  }
                ]
              }
            },
            oneAppointment: {
              initial: 'idle',
              entry: 'spawnAppointmentPopupMachine',
              exit: 'stopSpawnedAppointmentPopupMachines',
              on: {
                CLOSE: {
                  actions: ['clearFormData', 'clearAppointmentCounter'],
                  target: '#kiosk.landing.validating'
                },
                // MARK_AS_ARRIVED: {
                //   target: 'markingAsArrived'
                // },
                GOTO_SELF_SERVE_FORM: {
                  actions: [],
                  target: '#selfServeForm'
                }
              },
              states: {
                idle: {},
                error: {
                  exit: ['clearError']
                }
              }
            },
            multipleAppointments: {
              initial: 'findingPositionOfAppointment',
              entry: 'spawnAppointmentPopupMachine',
              exit: 'stopSpawnedAppointmentPopupMachines',
              on: {
                CLOSE: {
                  actions: ['clearFormData', 'clearAppointmentCounter'],
                  target: '#kiosk.landing.validating'
                },
                // JOIN_OFFICE_QUEUE: {
                //   target: 'joiningOfficeQueue'
                // },
                GOTO_SELF_SERVE_FORM: {
                  actions: [],
                  target: '#selfServeForm'
                }
              },
              states: {
                findingPositionOfAppointment: {
                  always: [
                    {
                      cond: 'isInFirstAppointment',
                      target: 'first'
                    },
                    {
                      cond: 'hasReachedLastAppointment',
                      target: 'last'
                    },
                    {
                      target: 'middle'
                    }
                  ]
                },
                first: {
                  on: {
                    NEXT_APPOINTMENT: {
                      actions: 'incrementAppointmentCounter',
                      target: 'findingPositionOfAppointment'
                    }
                  }
                },
                middle: {
                  on: {
                    NEXT_APPOINTMENT: {
                      actions: 'incrementAppointmentCounter',
                      target: 'findingPositionOfAppointment'
                    },
                    PREVIOUS_APPOINTMENT: {
                      actions: 'decrementAppointmentCounter',
                      target: 'findingPositionOfAppointment'
                    }
                  }
                },
                last: {
                  on: {
                    PREVIOUS_APPOINTMENT: {
                      actions: 'decrementAppointmentCounter',
                      target: 'findingPositionOfAppointment'
                    }
                  }
                }
              }
            }
          }
        },
        noAppointmentFound: {
          always: {
            target: 'selfServeForm'
          }
        },
        selfServeForm: {
          tags: ['newAppointment'],
          id: 'selfServeForm',
          entry: ['spawnLotInfoItemsMachineAction'],
          // exit: ['stopLotInfoItemsMachine'],
          meta: {
            fillInformationHelpText: 'towProviderSelfServeFormFillFormHelpText'
          },
          on: {
            GOTO_NON_TOW_PROVIDER_STATE: {
              target: '#nonTowProvider'
            }
          },
          initial: 'checkingForTypeOfUser',
          states: {
            checkingForTypeOfUser: {
              always: [
                {
                  cond: 'isUserTypeMember',
                  target: 'member'
                },
                {
                  target: 'transporter'
                }
              ]
            },
            member: {
              initial: 'editingForm',
              states: {
                editingForm: {
                  tags: ['memberEditingForm'],
                  id: 'memberEditingForm',
                  initial: 'validating',
                  on: {
                    ...formFieldsEvents,
                    MEMBER_REQUEST_ID_CHANGE: {
                      actions: [
                        'setFormFieldValue',
                        'sendMemberNumberChangeUpdate'
                      ],
                      target: '.validating'
                    }
                  },
                  states: {
                    validating: {
                      initial: 'idle',
                      always: [
                        {
                          cond: 'isStackUS',
                          target: '.stackUSValidation'
                        },
                        {
                          target: '.stackUKValidation'
                        }
                      ],
                      states: {
                        idle: {},
                        stackUSValidation: {
                          always: [
                            {
                              cond: 'areTowProvidersFieldsValidUS',
                              target: '#memberEditingForm.errorFree'
                            },
                            {
                              target: '#memberEditingForm.error'
                            }
                          ]
                        },
                        stackUKValidation: {
                          always: [
                            {
                              cond: 'areTowProvidersFieldsValidUK',
                              target: '#memberEditingForm.errorFree'
                            },
                            {
                              target: '#memberEditingForm.error'
                            }
                          ]
                        }
                      }
                    },
                    errorFree: {
                      on: {
                        CHECK_SCHEDULE: {
                          actions: 'sendTextSelectedGAEvent',
                          target: 'checkingForLotsValidity'
                        }
                      }
                    },
                    error: {
                      tags: ['error'],
                      exit: ['clearError']
                    },
                    serverError: {
                      tags: ['error', 'serverError'],
                      on: {
                        CHECK_SCHEDULE: {
                          actions: 'sendTextSelectedGAEvent',
                          target: 'checkingForLotsValidity'
                        }
                      }
                    },
                    checkingForLotsValidity: {
                      tags: ['checkingForLotsValidity'],
                      invoke: {
                        id: 'checkForLotsValidityService',
                        src: 'checkForLotsValidityService',
                        onDone: [
                          {
                            cond: 'areAllLotsValid',
                            actions: ['setValidatedPickupLots'],
                            target: 'checkIfLotsInSelectedYard'
                          },
                          {
                            actions: ['setValidatedPickupLots'],
                            target: 'lotsInvalid'
                          }
                        ],
                        onError: {
                          actions: 'setServerError',
                          target: '#memberEditingForm.serverError'
                        }
                      }
                    },
                    checkIfLotsInSelectedYard: {
                      tags: ['checkIfLotsInSelectedYard'],
                      always: [
                        {
                          cond: 'areLotsInSelectedYard',
                          target: '#selfServeForm.schedulingAppointment'
                        },
                        {
                          target: '#selfServeForm.lotsFacilityInfo'
                        }
                      ]
                    },
                    lotsInvalid: {
                      tags: ['lotsInvalid'],
                      initial: 'idle',
                      entry: [],
                      on: {
                        CLOSE: {
                          actions: [],
                          target: '#memberEditingForm'
                        },
                        JOIN_OFFICE_QUEUE: {
                          actions: ['syncGatePassPinLotDetails'],
                          target: '.joiningOfficeQueue'
                        }
                      },
                      states: {
                        idle: {},
                        joiningOfficeQueue: {
                          tags: ['joiningOfficeQueue'],
                          invoke: {
                            id: 'joinQueue',
                            src: 'joinQueue',
                            onDone: {
                              actions: ['setVirtualQueueId'],
                              target: '#kiosk.virtualQueueSummary'
                            },
                            onError: {
                              actions: 'setServerError',
                              target: '#memberEditingForm.serverError'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            transporter: {
              initial: 'editingForm',
              states: {
                editingForm: {
                  tags: ['transporterEditingForm'],
                  id: 'transporterEditingForm',
                  initial: 'validating',
                  on: {
                    ...formFieldsEvents
                  },
                  states: {
                    validating: {
                      initial: 'idle',
                      always: [
                        {
                          cond: 'isStackUS',
                          target: '.stackUSValidation'
                        },
                        {
                          target: '.stackUKValidation'
                        }
                      ],
                      states: {
                        idle: {},
                        stackUSValidation: {
                          always: [
                            {
                              cond: 'areTowProvidersFieldsValidUS',
                              target: '#transporterEditingForm.errorFree'
                            },
                            {
                              target: '#transporterEditingForm.error'
                            }
                          ]
                        },
                        stackUKValidation: {
                          always: [
                            {
                              cond: 'areTowProvidersFieldsValidUK',
                              target: '#transporterEditingForm.errorFree'
                            },
                            {
                              target: '#transporterEditingForm.error'
                            }
                          ]
                        }
                      }
                    },
                    errorFree: {
                      on: {
                        CHECK_SCHEDULE: {
                          actions: 'sendTextSelectedGAEvent',
                          target: 'checkingForLotsValidity'
                        }
                      }
                    },
                    error: {
                      tags: ['error'],
                      exit: ['clearError']
                    },
                    serverError: {
                      tags: ['error', 'serverError'],
                      on: {
                        CHECK_SCHEDULE: {
                          actions: 'sendTextSelectedGAEvent',
                          target: 'checkingForLotsValidity'
                        }
                      }
                    },
                    checkingForLotsValidity: {
                      tags: ['checkingForLotsValidity'],
                      invoke: {
                        id: 'checkForLotsValidityService',
                        src: 'checkForLotsValidityService',
                        onDone: [
                          {
                            cond: 'areAllLotsValid',
                            actions: [
                              'setValidatedPickupLots',
                              'emptyPickupSublot'
                            ],
                            target: 'checkIfLotsInSelectedYard'
                          },
                          {
                            actions: [
                              'setValidatedPickupLots',
                              'emptyPickupSublot'
                            ],
                            target: 'lotsInvalid'
                          }
                        ],
                        onError: {
                          actions: 'setServerError',
                          target: '#transporterEditingForm.serverError'
                        }
                      }
                    },
                    checkIfLotsInSelectedYard: {
                      tags: ['checkIfLotsInSelectedYard'],
                      always: [
                        {
                          cond: 'areLotsInSelectedYard',
                          target: '#selfServeForm.schedulingAppointment'
                        },
                        {
                          target: '#selfServeForm.lotsFacilityInfo'
                        }
                      ]
                    },
                    lotsInvalid: {
                      tags: ['lotsInvalid'],
                      initial: 'idle',
                      on: {
                        CLOSE: {
                          target: '#transporterEditingForm'
                        },
                        JOIN_OFFICE_QUEUE: {
                          actions: ['syncGatePassPinLotDetails'],
                          target: '.joiningOfficeQueue'
                        }
                      },
                      states: {
                        idle: {},
                        joiningOfficeQueue: {
                          tags: ['joiningOfficeQueue'],
                          invoke: {
                            id: 'joinQueue',
                            src: 'joinQueue',
                            onDone: {
                              actions: ['setVirtualQueueId'],
                              target: '#kiosk.virtualQueueSummary'
                            },
                            onError: {
                              actions: 'setServerError',
                              target: '#transporterEditingForm.serverError'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            lotsFacilityInfo: {
              tags: ['lotsFacilityInfo'],
              invoke: {
                id: 'getLotsFacilityInfo',
                src: 'getLotsFacilityInfo',
                onDone: [
                  {
                    cond: 'isSublot',
                    actions: ['setPickupSublot'],
                    target: 'schedulingAppointment'
                  },
                  {
                    target: 'schedulingAppointment'
                  }
                ],
                onError: {
                  actions: 'setServerError',
                  target: '#transporterEditingForm.serverError'
                }
              }
            },
            schedulingAppointment: {
              tags: ['schedulingAppointment'],
              initial: 'getSlotsDetails',
              on: {
                CLOSE: {
                  target: '#selfServeForm'
                }
              },
              states: {
                slotsloaded: {
                  tags: ['slotsloaded'],
                  initial: 'validatingSlotDetails',
                  on: {
                    SET_SELECTED_DATE: {
                      target: '.validatingSlotDetails',
                      actions: 'setDateToCtx',
                      cond: 'canSelectDate'
                    },
                    SET_SELECTED_SLOT: {
                      target: '.validatingSlotDetails',
                      actions: 'setSlotToCtx'
                    }
                  },
                  states: {
                    validatingSlotDetails: {
                      always: [
                        {
                          target: 'errorFree',
                          cond: 'isSlotSelected'
                        },
                        {
                          target: 'error'
                        }
                      ]
                    },
                    error: {
                      tags: ['error'],
                      exit: ['clearError']
                    },
                    errorFree: {
                      on: {
                        CONFIRM_APPOINTMENT: [
                          {
                            target: 'lotBillUnpaid',
                            cond: 'lotsUnpaidAndTodayDate'
                          },
                          {
                            actions: ['syncGatePassPinLotDetails'],
                            target:
                              '#selfServeForm.schedulingAppointment.confirmingAppointment'
                          }
                        ]
                      }
                    },
                    lotBillUnpaid: {
                      tags: ['lotBillUnpaid'],
                      initial: 'idle',
                      on: {
                        JOIN_OFFICE_QUEUE: {
                          actions: ['syncGatePassPinLotDetails'],
                          target: '.joiningOfficeQueue'
                        },
                        CLOSE: {
                          target: 'validatingSlotDetails'
                        }
                      },
                      states: {
                        idle: {},
                        joiningOfficeQueue: {
                          tags: ['lotBillUnpaid', 'joiningOfficeQueue'],
                          invoke: {
                            id: 'joinQueue',
                            src: 'joinQueue',
                            onDone: {
                              actions: ['setVirtualQueueId'],
                              target: '#kiosk.virtualQueueSummary'
                            },
                            onError: {
                              actions: ['setServerError'],
                              target:
                                '#selfServeForm.schedulingAppointment.slotsloaded'
                            }
                          }
                        }
                      }
                    },
                    serverError: {
                      tags: ['error', 'serverError'],
                      exit: ['clearError'],
                      on: {
                        CONFIRM_APPOINTMENT: [
                          {
                            target: 'lotBillUnpaid',
                            cond: 'lotsUnpaidAndTodayDate'
                          },
                          {
                            actions: ['syncGatePassPinLotDetails'],
                            target:
                              '#selfServeForm.schedulingAppointment.confirmingAppointment'
                          }
                        ]
                      }
                    }
                  }
                },
                getSlotsDetails: {
                  tags: ['getSlotsDetails'],
                  invoke: {
                    id: 'getSlotsService',
                    src: 'getSlotsService',
                    onDone: [
                      {
                        cond: 'hasSlots',
                        actions: 'setSlots',
                        target: 'slotsloaded'
                      },
                      {
                        target: 'noSlots'
                      }
                    ],
                    onError: {
                      actions: 'setServerError',
                      target: '#selfServeForm'
                    }
                  }
                },
                confirmingAppointment: {
                  tags: ['confirmingAppointment'],
                  invoke: {
                    id: 'joinQueueTransporter',
                    src: 'joinQueueTransporter',
                    onDone: [
                      {
                        cond: 'hasAppointmentId',
                        actions: ['setAppointmentId'],
                        target: '#kiosk.appointmentSummary'
                      },
                      {
                        actions: ['setVirtualQueueId'],
                        target: '#kiosk.appointmentVirtualQueueSummary'
                      }
                    ],
                    onError: {
                      actions: 'setServerError',
                      target: 'slotsloaded.serverError'
                    }
                  }
                },
                noSlots: {
                  tags: ['noSlots'],
                  initial: 'idle',
                  on: {
                    CLOSE: {
                      target: '#selfServeForm'
                    },
                    JOIN_OFFICE_QUEUE: {
                      actions: ['syncGatePassPinLotDetails'],
                      target: '.joiningOfficeQueue'
                    }
                  },
                  states: {
                    idle: {},
                    error: {
                      exit: ['clearError']
                    },
                    joiningOfficeQueue: {
                      tags: ['noSlots', 'joiningOfficeQueue'],
                      invoke: {
                        id: 'joinQueue',
                        src: 'joinQueue',
                        onDone: {
                          actions: ['setVirtualQueueId'],
                          target: '#kiosk.virtualQueueSummary'
                        },
                        onError: {
                          actions: 'setServerError',
                          target: 'error'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    nonTowProvider: {
      tags: ['kioskFlow'],
      id: 'nonTowProvider',
      meta: {
        fillInformationHelpText: 'nonTowProviderSelfServeFormFillFormHelpText'
      },
      entry: ['spawnLotInfoItemsMachineAction'],
      // exit: ['stopLotInfoItemsMachine'],
      on: {
        GOTO_TOW_PROVIDER_STATE: {
          target: '#selfServeForm'
        }
      },
      initial: 'checkIfUserIsMemberOrNot',
      states: {
        checkIfUserIsMemberOrNot: {
          always: [
            {
              cond: 'isUserTypeMember',
              target: 'member'
            },
            {
              target: 'nonMember'
            }
          ]
        },
        member: {
          tags: ['memberEditingForm'],
          initial: 'editingForm',
          states: {
            editingForm: {
              id: 'editingFormMember',
              initial: 'validating',
              on: {
                ...formFieldsEvents,
                MEMBER_REQUEST_ID_CHANGE: {
                  actions: 'setFormFieldValue',
                  target: '.validating'
                }
              },
              states: {
                validating: { ...validatingState(true) },
                error: {
                  tags: ['error'],
                  exit: ['clearError']
                },
                errorFree: {
                  tags: ['errorFree'],
                  on: {
                    JOIN_OFFICE_QUEUE: {
                      actions: ['sendTextSelectedGAEvent'],
                      target: '#kiosk.nonTowProvider.member.joiningOfficeQueue'
                    }
                  }
                },
                serverError: {
                  tags: ['error', 'serverError'],
                  exit: ['clearError'],
                  on: {
                    JOIN_OFFICE_QUEUE: {
                      actions: ['sendTextSelectedGAEvent'],
                      target: '#kiosk.nonTowProvider.member.joiningOfficeQueue'
                    }
                  }
                }
              }
            },
            joiningOfficeQueue: {
              tags: ['joiningOfficeQueue'],
              invoke: {
                id: 'joinQueue',
                src: 'joinQueue',
                onDone: {
                  actions: ['setVirtualQueueId'],
                  target: '#kiosk.virtualQueueSummary'
                },
                onError: {
                  actions: ['setServerError'],
                  target: 'editingForm.serverError'
                }
              }
            }
          }
        },
        nonMember: {
          initial: 'editingForm',
          states: {
            editingForm: {
              id: 'editingFormNonMember',
              initial: 'validating',
              on: {
                ...formFieldsEvents
              },
              states: {
                validating: { ...validatingState(false) },
                error: {
                  tags: ['error'],
                  exit: ['clearError']
                },
                errorFree: {
                  tags: ['errorFree'],
                  on: {
                    JOIN_OFFICE_QUEUE: {
                      actions: ['sendTextSelectedGAEvent'],
                      target:
                        '#kiosk.nonTowProvider.nonMember.joiningOfficeQueue'
                    }
                  }
                },
                serverError: {
                  tags: ['error', 'serverError'],
                  exit: ['clearError'],
                  on: {
                    JOIN_OFFICE_QUEUE: {
                      actions: ['sendTextSelectedGAEvent'],
                      target:
                        '#kiosk.nonTowProvider.nonMember.joiningOfficeQueue'
                    }
                  }
                }
              }
            },
            joiningOfficeQueue: {
              tags: ['joiningOfficeQueue'],
              invoke: {
                id: 'joinQueue',
                src: 'joinQueue',
                onDone: {
                  actions: ['setVirtualQueueId'],
                  target: '#kiosk.virtualQueueSummary'
                },
                onError: {
                  actions: ['setServerError'],
                  target: 'editingForm.serverError'
                }
              }
            }
          }
        }
      }
    },
    virtualQueueSummary: {
      tags: ['kioskSummary'],
      entry: [
        send((ctx) => ({
          type: 'NAVIGATE_TO',
          url: `/${ctx.language}/self-serve/${ctx.requestId}`
        }))
      ]
    },
    appointmentVirtualQueueSummary: {
      tags: ['appointmentWithKioskSummary'],
      entry: [
        send((ctx) => ({
          type: 'NAVIGATE_TO',
          url: `/${ctx.language}/self-serve/${ctx.requestId}?appointmentCreated=true`
        }))
      ]
    },
    appointmentSummary: {
      tags: ['appointmentSummary'],
      entry: [
        send((ctx) => ({
          type: 'NAVIGATE_TO',
          url: `/${ctx.language}/appointment/${ctx.appointmentId}`
        }))
      ]
    }
  }
})

export default machine
