import { Machine, sendParent, send } from 'xstate'

const machine = Machine({
  id: 'lot',
  initial: 'editing',
  states: {
    editing: {
      initial: 'checkingTypeOfUser',
      on: {
        LOT_NUMBER_CHANGE: {
          actions: 'setLotNumber'
        },
        REMOVE: {
          actions: [sendParent((ctx) => ({ type: 'REMOVE_LOT', id: ctx.id }))]
        },
        LOT_ITEM_BLUR: {
          actions: [
            sendParent((ctx) => ({ type: 'UPDATE_LOT_ITEM', data: { ...ctx } }))
          ]
        }
      },
      states: {
        checkingTypeOfUser: {
          on: {
            '': [
              {
                cond: 'isTowProvider',
                target: 'towProvider'
              },
              {
                target: 'nonTowProvider'
              }
            ]
          }
        },
        towProvider: {
          on: {
            ADD: {
              actions: [sendParent(() => ({ type: 'ADD_LOT' }))]
            },
            LOT_ITEM_POPUP: {
              actions: ['setshowHidePopUp'],
              target: '.showPopup'
            }
          },
          type: 'parallel',
          states: {
            lotNumber: {
              on: {
                LOT_NUMBER_CHANGE: {
                  actions: ['setLotNumber', send('VALIDATE')],
                  target: '.validating'
                },
                LOT_COPART_DELIVERY: {
                  target: '.checkForCopartDelivery'
                },
                VALIDATE: {
                  target: '.validating'
                }
              },
              initial: 'validating',
              states: {
                checkForCopartDelivery: {
                  invoke: {
                    src: 'checkCopartDeliveryService', // checkCopartDeliveryService
                    onDone: {
                      actions: 'setIsCopartDelivery'
                    }
                  }
                },
                validating: {
                  always: [
                    {
                      target: 'errorFree',
                      cond: 'isLotNumberFilledOrGatePassPinFilled'
                    },
                    {
                      target: 'error'
                    }
                  ]
                },
                error: {},
                errorFree: {}
              }
            },
            memberNumber: {
              on: {
                MEMBER_NUMBER_CHANGE: {
                  actions: ['setMemberNumber', send('VALIDATE')],
                  target: '.validating'
                },
                MEMBER_NUMBER_UPDATE_FROM_PARENT: {
                  actions: ['setMemberNumber', send('VALIDATE')],
                  target: '.validating'
                },
                VALIDATE: {
                  target: '.validating'
                }
              },
              initial: 'validating',
              states: {
                validating: {
                  always: [
                    {
                      target: 'errorFree',
                      cond: 'isMemberNumberFilledOrGatePassPinFilled'
                    },
                    {
                      target: 'error'
                    }
                  ]
                },
                error: {},
                errorFree: {}
              }
            },
            gatePassPin: {
              on: {
                GATE_PASS_PIN_CHANGE: {
                  actions: ['setGatePassPin', send('VALIDATE')],
                  target: '.validating'
                },
                VALIDATE: {
                  target: '.validating'
                }
              },
              initial: 'validating',
              states: {
                validating: {
                  always: [
                    {
                      target: 'errorFree',
                      cond: 'isGatePassPinFilledOrLotAndMemberNumberFilled'
                    },
                    {
                      target: 'error'
                    }
                  ]
                },
                error: {},
                errorFree: {}
              }
            },
            pickReasons: {
              on: {
                PICK_REASONS: [
                  {
                    cond: 'isPickupVehicleReasonUnSelected',
                    actions: [
                      'setReasons',
                      sendParent((ctx) => ({
                        type: 'UPDATE_REASONS',
                        data: { ...ctx }
                      }))
                    ],
                    target: '#lot.editing.nonTowProvider'
                  },
                  {
                    actions: 'setReasons'
                  }
                ]
              }
            },
            showPopup: {
              meta: { warning: 'gatepassInfo' }
            }
          }
        },
        nonTowProvider: {
          on: {
            ADD: {
              actions: [sendParent((ctx) => ({ type: 'ADD_LOT' }))]
            },
            PICK_REASONS: [
              {
                cond: 'isInTowProviderFlow',
                actions: [
                  'setReasons',
                  sendParent((ctx) => ({
                    type: 'UPDATE_REASONS',
                    data: { ...ctx }
                  }))
                ],
                target: '#lot.editing.towProvider'
              },
              {
                actions: 'setReasons'
              }
            ],
            LOT_NUMBER_CHANGE: {
              actions: 'setLotNumber'
            },
            LOT_COPART_DELIVERY: {
              target: '.checkForCopartDelivery'
            }
          },
          states: {
            checkForCopartDelivery: {
              invoke: {
                src: 'checkCopartDeliveryService',
                onDone: {
                  actions: 'setIsCopartDelivery',
                  target: 'errorFree'
                }
              }
            },
            error: {},
            errorFree: {}
          }
        }
      }
    }
  }
})

export default machine
