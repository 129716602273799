import React from 'react'
import { keys, propOr, path } from 'ramda'
import { Table, Icon } from 'semantic-ui-react'
import locale from '../../utils/locale'

import './style.css'

const TableRow = ({ data, row, tableConfig }) => {
  const kepMapping = path([row, 'key'], tableConfig)
  const displayedData = tableConfig[row].render
    ? tableConfig[row].render(data)
    : propOr('', kepMapping, data)

  return (
    <Table.Row>
      <Table.Cell className="SummaryTableCell">
        {tableConfig[row].headerText()}
      </Table.Cell>
      <Table.Cell className="SummaryTableCell">{displayedData}</Table.Cell>
    </Table.Row>
  )
}

export default function SummaryTable({
  send,
  tableConfig = {},
  data = {},
  showSummary,
  hideLotsSummary
}) {
  sessionStorage.setItem('yardFromUrl', data?.appointmentData?.facilityId)
  return (
    <>
      <Table unstackable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="SummaryTableHeader"
              colSpan="2"
              onClick={() => send('TOGGLE_SUMMARY')}
            >
              <span>
                {locale('summary')}
                <Icon name={showSummary ? 'caret up' : 'caret down'} />
              </span>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {showSummary &&
            keys(tableConfig).map((row) => (
              <TableRow
                tableConfig={tableConfig}
                key={row}
                data={data}
                row={row}
              />
            ))}
        </Table.Body>
      </Table>
      {!hideLotsSummary && (
        <Table unstackable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="SummaryTableHeader" colSpan="3">
                {locale('lotsSummary')}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell colSpan="2" className="LotTableHeader">
                {locale('lotNumbers')}
              </Table.HeaderCell>
              <Table.HeaderCell className="LotTableHeader">
                {locale('lotStatus')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.appointmentData.lotDetails.map((details, index) => (
              <Table.Row key={`detail_${index}`}>
                <Table.Cell className="SummaryTableCell" colSpan="2">
                  {details.lotNumber}
                </Table.Cell>
                <Table.Cell className="SummaryTableCell">
                  {locale(details.lotStatusDescription)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  )
}
