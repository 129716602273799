import moment from 'moment'
import { isNil, isEmpty, head } from 'ramda'
import {
  convertDateToYardTimeZone,
  isTowProviderCheck,
  checkWithRangeForYard,
  skipgeofence
} from '../../utils'

const STACK = process.env.REACT_APP_STACK || 'c'

const alreadyArrivedErrorMessage =
  'Requested action is invalid: ARRIVE not allowed for an Appointment that is ARRIVED'

const isValueEmpty = (val) => isNil(val) || isEmpty(val)

export const areAllFieldsFilledAndPhoneNumberValid = (ctx) => {
  const {
    form: { inputs }
  } = ctx

  return (
    inputs?.facilityId &&
    inputs?.stateCode &&
    ctx.form.yardOpen &&
    inputs?.parsedPhone?.isValid &&
    !(isValueEmpty(inputs.userType) || isValueEmpty(inputs.reasonCodes))
  )
}

export const isTowProvider = (ctx) => {
  return isTowProviderCheck(
    ctx.form.inputs.userType,
    ctx.form.inputs.reasonCodes,
    ctx.form.inputs.facilityId
  )
}

export const hasValidPhoneNumberAndFacilityId = (ctx, event) => {
  return event.parsedPhone && !isValueEmpty(ctx.form.inputs.facilityId)
}

export const hasUserType = (ctx, event) => {
  return !!event.data.userType
}

export const hasAppointment = (ctx, event) => {
  return event.data.hasAppointment
  // return ctx.form.inputs.parsedPhone.rawPhoneNumber === '8888888888'
}

export const hasOnlyOneAppointment = (ctx) => {
  return ctx.transporter.appointments?.length === 1
}

export const areAllLotsValid = (ctx, event) => {
  return !event.data.some((lot) => !(isNil(lot.errors) || isEmpty(lot.errors)))
  // return ctx.form.inputs.items.find((item) => item.lot_number === '9999')
}

export const areLotsInSelectedYard = (ctx) =>
  head(ctx.validatedPickupLots).facility_id === ctx.form.inputs.facilityId

export const isSublot = (ctx, event) => {
  const facility = event.data
  return facility?.facilityTypeCode === 'S'
}

export const hasSlots = (_, event) => {
  return Object.values(event.data).some((slot) => !isValueEmpty(slot))
}

export const canSelectDate = (ctx, event) =>
  !isEmpty(ctx.schedule.availableSlots[event.date])

export const hasAppointmentId = (ctx, event) => event.data.appointmentId

export const isAppointmentAlreadyMarked = (ctx, event) => {
  const error = event?.data?.response?.data?.message?.[0]

  if (error?.appointment_status[0] === alreadyArrivedErrorMessage) return true

  return false
}

export const isUserTypeMember = (ctx, event) => {
  return (
    ctx.form.inputs.userType === 'MBR' || ctx.form.inputs.userType === 'PMRMBR'
  )
}

export const isStackUS = (ctx, event) => {
  return STACK === 'c'
}

export const areTowProvidersFieldsValidUS = (ctx, event) => {
  const {
    form: { inputs }
  } = ctx

  const requiredFieldsChecks =
    inputs?.userType &&
    inputs?.firstName &&
    inputs?.lastName &&
    inputs?.truckType &&
    inputs?.licensePlateNumber &&
    inputs?.licenseState &&
    !isValueEmpty(inputs?.reasonCodes)

  const requiredMemberFieldsCheck = inputs?.memberRequestId

  const lotInfoItemsCheck = ctx.lotInfoItemsMachineRef?.state?.matches(
    'editing.towProvider.errorFree'
  )

  return inputs?.userType === 'MBR'
    ? requiredFieldsChecks && lotInfoItemsCheck && requiredMemberFieldsCheck
    : requiredFieldsChecks && lotInfoItemsCheck
}

export const areTowProvidersFieldsValidUK = (ctx, event) => {
  const {
    form: { inputs }
  } = ctx

  const requiredFieldsChecks =
    inputs?.userType &&
    inputs?.firstName &&
    inputs?.lastName &&
    inputs?.truckType &&
    inputs?.licensePlateNumber &&
    inputs?.licenseState &&
    !isValueEmpty(inputs?.reasonCodes)

  const lotInfoItemsCheck = ctx.lotInfoItemsMachineRef?.state?.matches(
    'editing.towProvider.errorFree'
  )

  return requiredFieldsChecks && lotInfoItemsCheck
}

export const areNonTowProvidersFieldsValidUS = (ctx, event) => {
  const {
    form: { inputs }
  } = ctx
  const requiredFieldsChecks =
    inputs?.userType &&
    inputs?.firstName &&
    inputs?.lastName &&
    !isValueEmpty(inputs?.reasonCodes)

  const lotInfoItemsCheck = ctx.lotInfoItemsMachineRef?.state?.matches(
    'editing.nonTowProvider.errorFree'
  )

  return requiredFieldsChecks && lotInfoItemsCheck
}

export const areNonTowProvidersAndMemberFieldsValidUS = (ctx, event) => {
  const {
    form: { inputs }
  } = ctx

  const requiredFieldsChecks =
    inputs?.userType &&
    inputs?.firstName &&
    inputs?.lastName &&
    !isValueEmpty(inputs?.reasonCodes) &&
    inputs?.memberRequestId

  const lotInfoItemsCheck = ctx.lotInfoItemsMachineRef?.state?.matches(
    'editing.nonTowProvider.errorFree'
  )

  return requiredFieldsChecks && lotInfoItemsCheck
}

export const areNonTowProvidersFieldsValidUK = (ctx, event) => {
  const {
    form: { inputs }
  } = ctx

  const requiredFieldsChecks =
    inputs?.userType &&
    inputs?.firstName &&
    inputs?.lastName &&
    !isValueEmpty(inputs?.reasonCodes)

  const lotInfoItemsCheck = ctx.lotInfoItemsMachineRef?.state?.matches(
    'editing.nonTowProvider.errorFree'
  )

  return requiredFieldsChecks && lotInfoItemsCheck
}

export const areNonTowProvidersAndMemberFieldsValidUK = (ctx, event) => {
  const {
    form: { inputs }
  } = ctx

  const requiredFieldsChecks =
    inputs?.userType &&
    inputs?.firstName &&
    inputs?.lastName &&
    !isValueEmpty(inputs?.reasonCodes) &&
    inputs?.memberRequestId

  const lotInfoItemsCheck = ctx.lotInfoItemsMachineRef?.state?.matches(
    'editing.nonTowProvider.errorFree'
  )

  return requiredFieldsChecks && lotInfoItemsCheck
}

export const isTextAllowed = (ctx) => {
  return ctx.form?.inputs?.isTextAllowed
}

export const hasFacilityId = (ctx, event) => {
  return event.facilityId
}

export const hasReachedLastAppointment = (ctx, event) => {
  return ctx.appointmentCounter === ctx.transporter.appointments.length - 1
}

export const isInFirstAppointment = (ctx, event) => {
  return ctx.appointmentCounter === 0
}

export const isSlotSelected = (ctx) => !isNil(ctx.schedule.selectedSlot)

export const lotsUnpaidAndTodayDate = (ctx) =>
  ctx.schedule.selectedDate ===
    convertDateToYardTimeZone(moment(), ctx.timeZone).format('YYYY-MM-DD') &&
  ctx.validatedPickupLots.some((lot) => lot.billPaid === 'N')

export const isAppointmentDetailsServiceResponse400 = (_, event) =>
  event.data?.response?.status === 400

export const isLocationNotShared = (ctx) => {
  if (!skipgeofence()) {
    return !ctx.latitude && !ctx.longitude
  }
  return false
}
export const isLocationNotMatching = (ctx, event) => {
  const {
    form: { inputs }
  } = ctx
  if (!skipgeofence()) {
    return !checkWithRangeForYard(
      {
        lat: ctx.latitude,
        long: ctx.longitude
      },
      {
        lat: inputs?.latitude,
        long: inputs?.longitude
      }
    )
  }
  return false
}

export const isAppointmentSchedulingForToday = (ctx) =>
  convertDateToYardTimeZone(moment(), ctx.timeZone).isSame(
    convertDateToYardTimeZone(
      moment(ctx.schedule.selectedStartDateTime),
      ctx.timeZone
    ),
    'day'
  )
